import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Filter options, system preferences or a dark mode control – it's very likely that you've come across a
toggle button before. At first sight, toggle buttons are simple: They allow the user to change a setting
between two states, usually “on” and “off”.`}</p>
    <p>{`Unfortunately, there's no native HTML element for toggle buttons. Which is why there's a lot of different
custom implementations roaming the internet. Some of them, you guessed it, totally inaccessible nightmares.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABMjjbaZAk/8QAGxAAAgMAAwAAAAAAAAAAAAAAAgMAAREEEhP/2gAIAQEAAQUCU4fJRiJv5JWyjyd8llt//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB0QAAEDBQEAAAAAAAAAAAAAABEAAQIQEiIxQYH/2gAIAQEABj8CtlzSMnl6sHDVIX//xAAbEAADAQADAQAAAAAAAAAAAAAAAREhMUFhcf/aAAgBAQABPyGy5rsgh59S5aNtV59GNIioy+Nl5lP/2gAMAwEAAgADAAAAEJQ//8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBMVH/2gAIAQMBAT8QWbHh/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qh//EAB4QAQACAQQDAAAAAAAAAAAAAAEAESExUZGxQWGh/9oACAEBAAE/EM8oFrY2759Tx0s8qaFGz1KdcAVy+wBazC6a1FRTNXC4NaNp/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A guy standing behind a DJ desk with many buttons and knobs.",
          "title": "A guy standing behind a DJ desk with many buttons and knobs.",
          "src": "/static/89c49ec501cf8d714d0b60d7428a8f5f/e5166/pexels-gaby-tenda-dj-desk.jpg",
          "srcSet": ["/static/89c49ec501cf8d714d0b60d7428a8f5f/f93b5/pexels-gaby-tenda-dj-desk.jpg 300w", "/static/89c49ec501cf8d714d0b60d7428a8f5f/b4294/pexels-gaby-tenda-dj-desk.jpg 600w", "/static/89c49ec501cf8d714d0b60d7428a8f5f/e5166/pexels-gaby-tenda-dj-desk.jpg 1200w", "/static/89c49ec501cf8d714d0b60d7428a8f5f/b17f8/pexels-gaby-tenda-dj-desk.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Gaby Tenda / pexels.com`}</em></p>
    <p>{`I created a `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/punk-api-beer-list/"
      }}>{`demo app`}</a>{` in React with accessible
toggle buttons, which I'd like to show you. I'm not sure that it's the perfect solution. So I'm
eager for some feedback.`}</p>
    <h2>{`Demo: Beer List with Toggle Buttons`}</h2>
    <p>{`In my demo, I display a list of beers. Users can switch between weak and strong beers using toggle
buttons. Give it a try:`}</p>
    <iframe src="https://alexlehner86.github.io/punk-api-beer-list/" title="Demo Page Beer List with weak/strong toggle button" loading="lazy"></iframe>
    <p>{`If you inspect the code, you'll see that I used a group of buttons and applied
the `}<InlineCode mdxType="InlineCode">{`switch`}</InlineCode>{` role to them. Before we take a closer look at the technical details,
I want to explain what I wanted to achieve with the control.`}</p>
    <h2>{`Goals and Requirements`}</h2>
    <ol>
      <li parentName="ol">{`The control should clearly convey its functionality, both visually and to assistive technologies.`}</li>
      <li parentName="ol">{`It should indicate its current state, both visually and to assistive technologies.`}</li>
      <li parentName="ol">{`Users should be able to operate the control with the keyboard alone, duh!`}</li>
      <li parentName="ol">{`Only one option (“weak” or “strong”) can be active at one time. If one option is switched on,
the other one is switched off.`}</li>
      <li parentName="ol">{`Switching between the two options should be a deliberate act by the user and not happen by accident.`}</li>
    </ol>
    <h2>{`Semantic Markup and Keyboard Operability`}</h2>
    <p>{`You could use radio buttons and change their styling with CSS. I decided not to use them because they're
automatically selected on focus (not what I want!) and they're standard form elements. Web forms should
not trigger a change of context until hitting the submit button.`}</p>
    <p>{`So, I decided to use a `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` element for each selectable option (“weak” and “strong”)
and apply the `}<InlineCode mdxType="InlineCode">{`switch`}</InlineCode>{` role. The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-1.2/#switch"
      }}>{`specification`}</a>{`
for the role states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A type of checkbox that represents on/off values, as opposed to checked/unchecked values.`}</p>
    </blockquote>
    <p>{`My `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/punk-api-beer-list/tree/main/src/components/TwoStateSwitch"
      }}>{`React component`}</a>{`
contains a toggle button for each option. This way, I can label them individually and clearly convey their
state (on or off). Furthermore, the two buttons are grouped inside a `}<InlineCode mdxType="InlineCode">{`section`}</InlineCode>{` element
with the `}<InlineCode mdxType="InlineCode">{`group`}</InlineCode>{` role. Take a look at the JSX code:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<section className={styles.container} role="group" aria-label={switchLabel}>
    <p>{switchLabel}:</p>
    <div className={styles.switchWrapper}>
        <button
            type="button"
            role="switch"
            aria-checked={selectedState === stateValues[0]}
            onClick={toggleSwitch}
        >
            {stateLabels[0]}
        </button>
        <button
            type="button"
            role="switch"
            aria-checked={selectedState === stateValues[1]}
            onClick={toggleSwitch}
        >
            {stateLabels[1]}
        </button>
    </div>
</section>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Users can focus the buttons with the tab key and toggle the state with the space or enter key.`}</p>
    <h2>{`Custom Styling with CSS`}</h2>
    <p>{`The toggle buttons' styling visually conveys their functionality and current state. When an option is
selected, I invert its colors and add a vertical line using the `}<InlineCode mdxType="InlineCode">{`::after`}</InlineCode>{` pseudo-element.
Here's an excerpt from my component's SCSS file:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/* Invert background and text colors */
button[aria-checked="true"] {
    background: var(--light-black);
    color: white;
}
/* Add vertical line to highlight selected option */
button[aria-checked="true"]:after {
    content: "";
    position: absolute;
    bottom: 0.2em;
    top: 0.2em;
}
button[aria-checked="true"]:first-of-type:after {
    right: 0.2em;
    border-right: 0.3em solid var(--primary-color);
}
button[aria-checked="true"]:last-of-type:after {
    left: 0.2em;
    border-left: 0.3em solid var(--primary-color);
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Feedback`}</h2>
    <p>{`So, that's it. My proposed solution for accessible toggle buttons. Do you love it, do you hate it?
Hit me up on Twitter, Mastodon or GitHub!`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/switch_role"
        }}>{`ARIA: switch role (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-1.2/#switch"
        }}>{`WAI-ARIA 1.2 (specification)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://joshcollinsworth.com/blog/accessible-toggle-buttons"
        }}>{`Building Accessible Toggle Buttons (Alternative Approach by Josh Collinsworth)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      